@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: inline-flex;
  flex-direction: column;
  text-decoration: none;
  color: vars.$black;
  gap: 5px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(5px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(2px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(2px, vars.$small);
  }
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.logo {
  height: 30px;
  color: vars.$black;
  @media screen and (max-width: vars.$big) {
    height: clamp(25px, adaptSize(30px, vars.$big), 40px);
  }

  @media screen and (max-width: vars.$medium) {
    height: adaptSize(30px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    height: clamp(25px, adaptSize(20px, vars.$small), 40px);
  }
}

.info-icon {
  cursor: pointer;
  height: 20px;
  overflow: visible;
  color: vars.$blue;
  &:hover {
    color: currentColor;
  }
  @media screen and (max-width: vars.$big) {
    height: clamp(15px, adaptSize(20px, vars.$big), 25px);
  }

  @media screen and (max-width: vars.$medium) {
    height: clamp(15px, adaptSize(15px, vars.$medium), 20px);
  }

  @media screen and (max-width: vars.$small) {
    height: clamp(15px, adaptSize(20px, vars.$small), 20px);
  }
}

.name {
  font-size: 12px;
}
