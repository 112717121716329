@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 530px;
  border-radius: 20px;
  border: 2px solid currentColor;
  color: vars.$blue;
  transition: all 0.5s ease;
  cursor: pointer;
  &-active {
    background: vars.$blue;
    color: vars.$white;
  }
  &-error {
    background: vars.$white;
    color: red;
  }
  & > * {
    pointer-events: none;
  }
  & > svg {
    margin-bottom: 26px;
  }
  @media screen and (max-width: vars.$big) {
    height: adaptSize(530px, vars.$big);
    border-radius: adaptSize(20px, vars.$big);
    & > svg {
      margin-bottom: adaptSize(26px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    max-width: none;
    height: adaptSize(400px, vars.$medium);
    border-radius: adaptSize(20px, vars.$medium);
    & > svg {
      margin-bottom: adaptSize(26px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    height: 70svh;
    max-height: 700px;
    border-radius: adaptSize(20px, vars.$small);
    & > svg {
      margin-bottom: adaptSize(26px, vars.$small);
    }
  }
}

.file-icon,
.forbidden-icon {
  height: 115px;
  width: 115px;
  @media screen and (max-width: vars.$big) {
    height: adaptSize(115px, vars.$big);
    width: adaptSize(115px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    height: adaptSize(60px, vars.$medium);
    width: adaptSize(60px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    height: adaptSize(60px, vars.$small);
    width: adaptSize(60px, vars.$small);
  }
}

.cloud-icon {
  width: 115px;
  @media screen and (max-width: vars.$big) {
    width: adaptSize(115px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(60px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    width: adaptSize(60px, vars.$small);
  }
}

.msg {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(18px, vars.$big);
    line-height: adaptSize(22px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(14px, vars.$medium);
    line-height: adaptSize(18px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(14px, vars.$small);
    line-height: adaptSize(18px, vars.$small);
  }
}

.input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
