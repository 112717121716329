@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";
@import "../../styles/fonts.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 20px;
}

.hidden {
  opacity: 0;
}
