@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  overflow: hidden;
}

.text-container {
  padding-top: 20px;
  max-width: 730px;
  @media screen and (max-width: vars.$big) {
    padding-top: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding-top: adaptSize(20px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    max-width: none;
    width: 100%;
    padding-top: adaptSize(20px, vars.$small);
  }
}
