@use "../../styles/vars.module" as vars;

.container {
  font-size: 16px;
  line-height: 18px;
  color: vars.$black;
  position: relative;
  cursor: pointer;
}

.selected {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: currentColor;
  }
}

.light {
  color: vars.$white;
}
