@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
  border: 2px solid vars.$blue;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
  min-height: 50vh;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(10px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(10px, vars.$medium);
    border-radius: adaptSize(15px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(10px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
  }
}
.my-masonry-grid_column {
  &:not(:first-child) {
    padding-left: 10px; /* gutter size */
  }
  background-clip: padding-box;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.title {
  background: vars.$blue;
  color: vars.$white;
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  max-width: 70%;
}

.masonry-img {
  opacity: 1;
  max-width: 100%;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 5px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &:hover {
    transform: scale(0.95);
  }
}
