@import "./functions.scss";

.H1 {
  font-size: 90px;
  line-height: 110px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(90px, vars.$big);
    line-height: adaptSize(110px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(74px, vars.$medium);
    line-height: adaptSize(92px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(32px, vars.$small);
    line-height: adaptSize(46px, vars.$small);
  }
}

.H2 {
  font-size: 54px;
  line-height: 68px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(54px, vars.$big);
    line-height: adaptSize(68px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(42px, vars.$medium);
    line-height: adaptSize(56px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(28px, vars.$small);
    line-height: adaptSize(44px, vars.$small);
  }
}

.H3 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(28px, vars.$big);
    line-height: adaptSize(40px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(21px, vars.$medium);
    line-height: adaptSize(32px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(17px, vars.$small);
    line-height: adaptSize(30px, vars.$small);
  }
}

.H4 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(32px, vars.$big);
    line-height: adaptSize(42px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(23px, vars.$medium);
    line-height: adaptSize(34px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(21px, vars.$small);
    line-height: adaptSize(32px, vars.$small);
  }
}

.T1 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(22px, vars.$big);
    line-height: adaptSize(32px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(17px, vars.$medium);
    line-height: adaptSize(26px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(17px, vars.$small);
    line-height: adaptSize(26px, vars.$small);
  }
}

.T2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(14px, vars.$big);
    line-height: adaptSize(24px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(12px, vars.$medium);
    line-height: adaptSize(20px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(11px, vars.$small);
    line-height: adaptSize(18px, vars.$small);
  }
}

.B1 {
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(21px, vars.$big);
    line-height: adaptSize(30px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(16px, vars.$medium);
    line-height: adaptSize(28px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(16px, vars.$small);
    line-height: adaptSize(28px, vars.$small);
  }
}

.B2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(18px, vars.$big);
    line-height: adaptSize(26px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(14px, vars.$medium);
    line-height: adaptSize(24px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(13px, vars.$small);
    line-height: adaptSize(23px, vars.$small);
  }
}

.Head {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(18px, vars.$big);
    line-height: adaptSize(26px, vars.$big);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(14px, vars.$medium);
    line-height: adaptSize(24px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(14px, vars.$small);
    line-height: adaptSize(24px, vars.$small);
  }
}
