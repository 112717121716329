@use "./styles/vars.module" as vars;
@import "./styles/functions.scss";

@font-face {
  font-family: Suisse Intl;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: Suisse Intl;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: vars.$black;
  font-family: Suisse Intl, sans-serif;
  background: vars.$blue2;
}

.app-container {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
  width: 100%;
  min-height: 100svh;
  height: 100%;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.max-width {
  max-width: vars.$max_width;
  justify-self: center;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: vars.$big) {
    padding: 0 adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding: 0 adaptSize(20px, vars.$medium);
    max-width: 100vw;
  }

  @media screen and (max-width: vars.$small) {
    padding: 0 adaptSize(10px, vars.$small);
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
}

.heatmap-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  & > canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & > img,
  & > canvas {
    border-radius: 10px;
  }
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}

.heatmap-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.original-img {
  max-height: 60svh;
  max-width: 100%;
}

.result-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dropzone {
  max-width: none !important;
}

.body {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dropzone {
  max-width: none !important;
}
